import React from 'react';
import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-default-single">
        <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-12 col-md-8">
              <h1 className="title">Page not found</h1>
              <h2 className="font-weight-light">The url you have entered doesn't appear to exist</h2>
            </div>
          </div>
        </div>
    </Layout>
    );
  }
}

export default NotFoundPage;
